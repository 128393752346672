<template>
  <div>
    <div class="container">
      <div class="example">
        <a-spin :spinning="loading">
          <a-row type="flex">
            <a-col :md="5"  class="right scroller">
              <!--组织树-->
              <div class="tree-top">
                <span>资源目录</span>
                <a-icon type="plus" class="tree-add" @click="append(currentNode[0]+'')" />
              </div>
              <a-tree
                default-expand-all
                :tree-data="treeData"
                :selectedKeys.sync="currentNode"
                :replaceFields="{children:'children', title:'name', key:'key' }"
                :key="treeDataKey"
                @select="onSelect"
              >
                <template slot="custom" slot-scope="custom">
                  <span>{{ custom.dataRef.name }}</span>
                  <span v-if="custom.dataRef.editStatus == 1">
                    <a-icon type="edit" class="tree-edit" @click.stop="edit(custom.dataRef)" />
                  <a-popconfirm placement="topRight" title="确认删除？" okText="确认" cancelText="取消" @confirm.stop="() => del(custom.dataRef)">
                    <a-icon type="delete" class="tree-delete" />
                  </a-popconfirm>
                  </span>
                </template>
              </a-tree>
            </a-col>
            <a-col :md="19" >
              <source-file-list v-if="currentNode.length>0" :current-id="currentNode[0]+''" ref="file_list" />
            </a-col>
          </a-row>
        </a-spin>
      </div>
      <source-dir-modal ref="dirAction" @ok="handleDirTree"></source-dir-modal>
    </div>
  </div>
</template>

<script>
import SourceFileList from './components/SourceFileList'
import SourceDirModal from './components/SourceDirModal'
import { listDirTree, delDirOrFile } from '@/api/system/source'

export default {
  name: 'Index',
  components: {
    SourceFileList,
    SourceDirModal
  },
  data () {
    return {
      // 树
      treeData: [],
      // 选中的节点
      currentNode: [],
      // 缓存选中
      cookieCurrent: [],
      loading: false,
      treeDataKey: 1
    }
  },
  mounted () {
    this.handleDirTree()
  },
  methods: {
    /**
     * 获取组织树信息
     */
    handleDirTree () {
      this.loading = true
      listDirTree(0).then((res) => {
        if (res['code'] === 200) {
          this.createdScopedSlots(res.rows)
          this.treeData = res.rows
          // 默认选择第一个
          this.currentNode[0] = res.rows[0].key
          // 缓存
          this.cookieCurrent[0] = res.rows[0].key
          this.treeDataKey++
        }
      }).finally(() => {
        this.loading = false
      })
    },
    /**
     *选择组织
     */
    onSelect () {
      // 第二次选择为空，将缓存给它
      if (this.currentNode.length < 1) {
        this.currentNode = this.cookieCurrent
      } else {
        this.cookieCurrent[0] = JSON.parse(JSON.stringify(this.currentNode[0]))
      }
      // 下一个生命周期
      this.$nextTick(() => {
        this.refreshTable()
      })
    },

    refreshTable () {
      this.$nextTick(()=> {
        this.$refs['file_list'].refresh()
      })
    },
    append (currentId) {
      this.$refs['dirAction'].append(currentId)
    },
    edit (item) {
      this.$refs['dirAction'].edit(item)
    },
    del (record) {
      delDirOrFile(record.id).then((res) => {
        if (res.code === 200) {
          this.$notification.success({
            message: '删除成功'
          })
          this.handleDirTree()
        }
      })
    },
    /**
     * 创建ScopedSlots
     * @param data
     */
    createdScopedSlots (data) {
      for (let i = 0; i < data.length; i++) {
        data[i].scopedSlots = { title: 'custom' }
        if (data[i].children) {
          this.createdScopedSlots(data[i].children)
        }
      }
    }
  }
}
</script>

<style scoped>
.container {
  background: #fff;
  padding: 8px;
}

.right {
  border-right: 1px solid #e8e8e8;
}

.tree-top {
  position: relative;
}

.tree-top span{
  font-weight: bold;
}

.tree-add {
  position: absolute;
  right: 6%;
}

.tree-item-add {
  position: absolute;
  right: 22%;
  font-size: 12px;
}

.tree-edit {
  position: absolute;
  right: 13%;
  font-size: 12px;
}

.tree-delete {
  position: absolute;
  right: 4%;
  font-size: 12px;
}
</style>
